<template>
    <div class="BSView" :ref="config.id"
         :style="assignStyle() ">
        <div ref="BSContent" id="canvasContent">
            <div class="pulldown-wrapper" v-show="isDisplay">
                <div v-show="!beforePullDown">
                    <span>下拉刷新</span>
                </div>
                <div v-show="beforePullDown">
                    <div v-show="isPullingDown">
                        <span>刷新完毕</span>
                    </div>
                    <div v-show="!isPullingDown"><span>刷新中</span></div>
                </div>
            </div>
            <slot></slot>
            <div class="pullup-wrapper" v-show="isPullDisplay">
                <div v-show="!isPullUpLoad" class="before-trigger">
                    <span class="pullup-txt">上拉加载</span>
                    
                </div>
                <div v-show="isPullUpLoad" class="after-trigger">
                    <!-- <span v-show="!isEnd&&isLoading" class="pullup-txt">123</span> -->
                    <span v-show="!isEnd&&!isLoading" class="pullup-txt">加载中...</span>
                    <span v-show="isEnd" class="pullup-txt">暂无数据</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import BScroll from '@better-scroll/core'
    import PullDown from '@better-scroll/pull-down'
    import Pullup from '@better-scroll/pull-up'

    BScroll.use(PullDown);
    BScroll.use(Pullup);

    export default {
        name: "BSView",
        props: {
            config: { //id  视图id  style样式(默认样式23行)  bs//滚动的对象 disabled//下拉刷新的状态
                type: Object,
                require: true,//该值是否为必须值，true就是没这个值就报错。与default二选一使用
            },
            PullUp: { //id  视图id  style样式(默认样式23行)  bs//滚动的对象 disabled//下拉刷新的状态
                type: Boolean,
                default: false,//该值是否为必须值，true就是没这个值就报错。与default二选一使用
            },
            isDisplay:{//下拉刷新字段是否显示
                type:Boolean,
                default: true
            },
            isPullDisplay:{
                type:Boolean,
                default: true
            },
            isEnd:{//已经是最底部了字段是否显示
                type:Boolean,
                default: true
            },
            isLoading:{
                type:Boolean,
                default: false
            }
        },
        data() {
            return {
                beforePullDown: true,  //下拉刷新触发的状态
                isPullingDown: true,  //下拉刷新加载完成触发的状态
                isPullUpLoad: true,  //上拉加载
                // isEnd:false
            }
        },
        methods: {
            assignStyle() {//样式
                return Object.assign({
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                }, this.config.style || {});
            },
            initBS() {//初始化滚动条
                let BScrollConfig = Object.assign({
                    scrollY: true, //滚动方向为 Y 轴
                    scrollX: false, // 滚动方向为 X 轴
                    click: true,  //这个很重要，，默认是会阻止浏览器的点击事件
                    tap:true,
                    disableMouse: false,
                    disableTouch: false,
                    mouseWheel: true,
                    translateZ: " translateZ(0)",
                    preventDefaultException:{
                        tagName:/^(INPUT|TEXTAREA|BUTTON|SELECT|AUDIO|DIV|IMG|SPAN)$/
                    },
                    bounce: true, //是否启用回弹动画效果
                    momentum: true, //当快速滑动时是否开启滑动惯性
                    bounceTime: 800, //回弹动画的动画时长。
                    resizePolling: 60,
                    probeType: 3,
                    preventDefault: true,
                    HWCompositing: true, // 是否启用硬件加速
                    useTransition: true, // 是否使用CSS3的Transition属性

                    //插件
                    // pullDownRefresh: true,
                    pullDownRefresh: {
                        threshold: 50,
                        stop: 30
                    },
                    pullUpLoad: true
                }, this.config.BSConfig || {});
                this.config.bs = new BScroll(this.$refs[this.config.id], BScrollConfig);
                this.$watch("config.disabledDown", {
                    handler(newQuery) {
                        if (newQuery) {
                            this.isPullingDown = true;
                            setTimeout(() => {
                                this.config.bs.finishPullDown();
                                setTimeout(() => {
                                    this.config.disabledDown = false;
                                    this.isPullingDown = false;
                                    this.beforePullDown = false;

                                    // this.config.bs.finishPullUp();
                                    this.config.bs.refresh();
                                    // this.config.bs.destroy();
                                }, 1000)
                            }, 500);
                        }
                    },
                    deep: true
                });
                this.$watch("config.disabledUp", {
                    handler(newQuery) {
                        if (newQuery) {
                            this.config.disabledUp = false;
                            this.config.bs.finishPullUp();
                            this.config.bs.refresh()
                        }
                    },
                    deep: true
                });

                this.config.bs.on('pullingDown', this.pullingDownHandler);
                this.config.bs.on('pullingUp', this.pullingUpHandler);

                //scrollEnd事件处理
                // this.config.bs.on("scrollEnd", (pos) => {
                //     console.log(this.$refs.BSContent.clientHeight - this.$refs[this.config.id].clientHeight);
                //     console.log(`当前值：${JSON.stringify(pos)}`);
                // })
            },
            pullingDownHandler() {     //配置下拉刷新
                this.isPullingDown = false;
                this.beforePullDown = true;
                this.$emit("pullingDown", "");
                // setTimeout(() => {
                //     this.config.isPullingDown = true;
                //     setTimeout(() => {
                //         this.config.beforePullDown = false;
                //         this.config.bs.finishPullDown();
                //     }, 3000)
                // }, 2000);
            },
            pullingUpHandler() {
                this.isPullUpLoad = true;
                this.$emit("PullUp", "");
            }
        },
        mounted() {
            this.initBS()
        },

    }
</script>

<style scoped>
    .BSView {
        overflow: hidden;
    }

    .pulldown-wrapper {
        position: absolute;
        width: 100%;
        top: -24px;
        text-align: center;
        font-size: 12px;
        color: #999999;
    }

    .pullup-wrapper {
        position: relative;
        width: 100%;
        text-align: center;
    }
</style>
